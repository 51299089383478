exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cubes-0-0-js": () => import("./../../../src/pages/cubes/0/0.js" /* webpackChunkName: "component---src-pages-cubes-0-0-js" */),
  "component---src-pages-cubes-0-1-js": () => import("./../../../src/pages/cubes/0/1.js" /* webpackChunkName: "component---src-pages-cubes-0-1-js" */),
  "component---src-pages-cubes-1-0-js": () => import("./../../../src/pages/cubes/1/0.js" /* webpackChunkName: "component---src-pages-cubes-1-0-js" */),
  "component---src-pages-cubes-1-1-js": () => import("./../../../src/pages/cubes/1/1.js" /* webpackChunkName: "component---src-pages-cubes-1-1-js" */),
  "component---src-pages-cubes-2-0-js": () => import("./../../../src/pages/cubes/2/0.js" /* webpackChunkName: "component---src-pages-cubes-2-0-js" */),
  "component---src-pages-cubes-2-1-js": () => import("./../../../src/pages/cubes/2/1.js" /* webpackChunkName: "component---src-pages-cubes-2-1-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-fixed-income-js": () => import("./../../../src/pages/fixed-income.js" /* webpackChunkName: "component---src-pages-fixed-income-js" */),
  "component---src-pages-help-center-js": () => import("./../../../src/pages/help-center.js" /* webpackChunkName: "component---src-pages-help-center-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stats-js": () => import("./../../../src/pages/stats.js" /* webpackChunkName: "component---src-pages-stats-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-trading-[cat]-[id]-[index]-invest-js": () => import("./../../../src/pages/trading/[cat]/[id]/[index]/invest.js" /* webpackChunkName: "component---src-pages-trading-[cat]-[id]-[index]-invest-js" */)
}

